<template>
  <v-list-item class="" nav link @click="$router.push(`/orderDetail/${order.id}/bid`)">
    <v-list-item-content>
      <v-list-item-title>{{ order.topic === null ? '( no topic)': order.topic }}</v-list-item-title>
      <v-list-item-subtitle class="caption">
        {{ order.id }} | {{ order.pages }} pages | {{ order.assignment_type }} |
        {{ order.language }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <div class="">
      <div class="text-h6 font-weight-bold">${{ !order.amount ? '0.00': order.amount }}</div>
      <div class="caption">
        {{ order.time }}
      </div>
    </div>

    <v-list-item-action>
      <div>
        <span v-if="$route.name === 'all' || $route.name === 'allAvailable'"> {{ !order.bids ? '0 bids' :`${order.bids.length} bids`}}</span>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "OrderComponent",
  mixins: [colorMxn],
  props: ["order"],
  data() {
    return {};
  }
};
</script>
